<template>
  <div class="wdnj">
    <div class="form-list">
      <div class="table-search">
        <h3>我的设备</h3>
        <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="item.name" :name="item.value" v-for="(item,index) in tabs" :key="index">
            <table-page :data="tableData" :columns="columns" :currentPage="query.current" :pageSize="query.size"
              :total="total" :loading="loading" :border="true" @sizeChange="handleSizeChange"
              @currentChange="handleCurrentChange">
              <template slot="operation" slot-scope="scope">
                <el-button type="text" size="small" @click="detailsClick(scope.row)">详情</el-button>
              </template>
            </table-page>
          </el-tab-pane>
        </el-tabs>

      </div>
    </div>
    <!-- 新增 -->
    <addEdit v-if="isAddEdit" @closeDialog2="closeDialog2" ref="isAddEdit"></addEdit>
  </div>
</template>

<script>
  import addEdit from './components/addEdit.vue'
  import { mapGetters, mapMutations } from "vuex";
  import TablePage from "@/components/table/table-page";
  import { selectMyAgricultural } from '@/api/njgl'
  import { tljColumn, njglDetailColumn, njDetailColumn, zdDetailColumn } from '../tableComlumns.js'

  export default {
    name: 'NowIndex',

    data() {
      return {
        activeName: '1',
        isAddEdit: false,
        columns: njglDetailColumn,
        loading: false,
        total: 0,
        query: {
          current: 1,
          size: 10,
        },
        tabs: [
          // {
          //   name: '拖拉机',
          //   value: '4',
          // },
          {
            name: '自走机',
            value: '1',

          }, {
            name: '机具',
            value: '2',
          },
          // {
          //   name: '终端',
          //   value: '3',
          // }
        ],
        tableData: [{
          orderType: '吉R00990',
        }, {
          orderType: '吉R04400',
        }, {
          orderType: 'SD7854-1',
        },
        {
          orderType: 'SD7854-2',
        }]
      };
    },
    computed: {
      ...mapGetters(["identityObj"]),
    },

    components: {
      TablePage,
      addEdit
    },
    watch: {
      activeName(newVal) {
        switch (newVal) {
          case '1':
            this.columns = njglDetailColumn;
            break;
          case '2':
            this.columns = njDetailColumn;
            break;
            // case '3':
            //   this.columns = zdDetailColumn
            //   break;
            // case '4':
            //   this.columns = tljColumn
            // 可以在这里添加处理逻辑，或者直接省略这两个case
            break;
          default:
            this.columns = []; // 或者根据需要设置默认值
            console.warn(`Unexpected activeIndex: ${newVal}`);
            break;
        }
      }
    },
    mounted() {
      this.selectMyAgricultural('1')
    },

    methods: {
      // 关闭弹框
      closeDialog2() {
        this.isAddEdit = false;
        // this.getList()
        this.selectMyAgricultural(this.activeName)
      },
      // 点击详情
      detailsClick(obj) {
        this.isAddEdit = true;
        let type = true
        this.$nextTick(() => {
          this.$refs.isAddEdit.EditData(this.activeName, obj, type);
        })
      },
      // 切换选项
      handleClick(tab, event) {
        // console.log(tab, event);
        // console.log(tab.paneName, 'paneName')
        this.selectMyAgricultural(tab.paneName)

      },
      //列表数据
      async selectMyAgricultural(type) {

        // console.log(this.identityObj, '查看身份类型')
        let params = {
          status: type,
          ...this.query,
          companyAuthId: this.identityObj.identityId
        }
        let res = await selectMyAgricultural(params)
        if (res.code == 0) {
          this.tableData = res.data.records
          this.total = Number(res.data.total)
        } else {
          this.$message({
            type: 'info', // success error warning
            message: res.msg,
            duration: 2000,
          })
        }
      },
      // 分页处理
      handleCurrentChange(page) {
        this.query.current = page;
        this.selectMyAgricultural(this.activeName);
      },
      // 分页处理
      handleSizeChange(size) {
        this.query = {
          current: 1,
          size: size,
        };
        this.selectMyAgricultural(this.activeName);
      },
    },
  };
</script>

<style lang="less" scoped>
  .form-list {
    .table-search {
      h3 {
        margin-left: 20px;
      }
    }
  }
</style>
